import { Fragment } from "react";
import TypeShowcase from "/components/home/typeShowcase/TypeShowcase";
import BrandShowCase from "/components/home/brandShowcase/BrandShowCase";
import AffiliateShowcase from "@components/home/affiliateShowcase/AffiliateShowcase";
import HeroHeader from "@components/home/HeroHeader";
import MainFeatureShowcase from "@components/home/MainFeatureShowcase";
import { getComponentByName } from "@lib/strapi/utils";
import { getPlaiceholder } from "plaiceholder";
import { NextSeo } from "next-seo";
import StandardFaqSection from "@components/faq/StandardFaqSection";
import { generateSeoObject } from "/utils/seo";
import { useRouter } from "next/router";
import homeData from "/data/home";
import Link from "next/link";

const seoData = {
  nl: {
    seoTitle: "Smartphone, tablet, computer en console reparatie.",
    seoDescription:
      "Uw smartphone, tablet, computer of console hersteld in 60 minuten, kom langs in Leuven zonder afspraak of laat je toestel ophalen in heel Belgie!",
  },
  en: {
    seoTitle: "Smartphone, tablet, computer and console repair.",
    seoDescription:
      "Your smartphone, tablet, computer or console repaired in 60 minutes, come by in Leuven without an appointment or have your device picked up anywhere in Belgium!",
  },
  fr: {
    seoTitle: "Réparation de smartphones, tablettes, ordinateurs et consoles.",
    seoDescription:
      "Votre smartphone, tablette, ordinateur ou console réparé en 60 minutes, passez à Louvain sans rendez-vous ou faites enlever votre appareil partout en Belgique !",
  },
};

const Home = ({ data, types, brands, reviews, placeholders, defaultTag }) => {
  const { locale } = useRouter();
  const content = seoData[locale];
  const seo = generateSeoObject({
    title: content.seoTitle,
    description: content.seoDescription,
    locale: locale,
    localePageMap: { nl: "", en: "", fr: "" },
  });

  return (
    <Fragment>
      <NextSeo
        title={seo.title}
        description={seo.description}
        languageAlternates={seo.languageAlternates}
        openGraph={seo.openGraph}
      />
      <HeroHeader
        data={getComponentByName(data.content, "hero-header.hero-header")}
        placeholders={placeholders}
      />
      <TypeShowcase
        items={types}
        data={getComponentByName(data.content, "home.type-showcase")}
      />
      
      <MainFeatureShowcase
        data={getComponentByName(data.content, "home.main-features")}
      />
      <BrandShowCase
        data={getComponentByName(data.content, "home.brands-showcase")}
        title1="Wij herstellen."
        title2="Alle merken"
        items={brands}
      />

      <AffiliateShowcase
        data={getComponentByName(data.content, "home.review-showcase")}
        items={reviews}
      />
      <StandardFaqSection defaultTag={defaultTag} />
    </Fragment>
  );
};

export async function getStaticProps({ locale }) {
  const strapi = await import("@lib/strapi");
  const types = await strapi.getTypes();
  const brands = await strapi.getBrands();
  // const home = await strapi.getHome(locale);
  const reviews = await strapi.getReviews();
  const home = homeData[locale];

  const heroHeader = getComponentByName(
    home.content,
    "hero-header.hero-header"
  );

  const placeholders = await Promise.all(
    heroHeader.box.map(async (box) => {
      const { base64, img } = await getPlaiceholder(
        box.image.formats.small.url
      );
      const { width, height, ...image } = img;
      return { imageProps: { ...image, blurDataURL: base64 } };
    })
  );

  const tags = {
    nl: {
      id: 10,
      Tag: null,
      locale: "nl",
      published_at: "2021-12-27T11:26:25.450Z",
      created_at: "2021-12-27T11:26:22.153Z",
      updated_at: "2021-12-27T11:26:25.466Z",
      tag: "Herstellingen",
      localizations: [
        {
          id: 26,
          locale: "fr",
          published_at: "2022-02-17T16:22:24.938Z",
        },
        {
          id: 25,
          locale: "en",
          published_at: "2022-02-17T16:22:11.855Z",
        },
      ],
    },
    en: {
      id: 25,
      Tag: null,
      locale: "en",
      published_at: "2022-02-17T16:22:11.855Z",
      created_at: "2022-02-17T16:21:56.358Z",
      updated_at: "2022-04-09T14:22:35.078Z",
      tag: "Repairs",
      localizations: [
        {
          id: 10,
          locale: "nl",
          published_at: "2021-12-27T11:26:25.450Z",
        },
        {
          id: 26,
          locale: "fr",
          published_at: "2022-02-17T16:22:24.938Z",
        },
      ],
    },
    fr: {
      id: 26,
      Tag: null,
      locale: "fr",
      published_at: "2022-02-17T16:22:24.938Z",
      created_at: "2022-02-17T16:22:20.285Z",
      updated_at: "2022-04-09T14:23:03.465Z",
      tag: "Réparations",
      localizations: [
        {
          id: 10,
          locale: "nl",
          published_at: "2021-12-27T11:26:25.450Z",
        },
        {
          id: 25,
          locale: "en",
          published_at: "2022-02-17T16:22:11.855Z",
        },
      ],
    },
  };

  return {
    props: {
      data: home,
      types,
      brands,
      reviews,
      placeholders: placeholders,
      defaultTag: tags[locale],
    },
  };
}

export default Home;
