import { motion } from "framer-motion";
import { ArrowRightIcon } from "@heroicons/react/solid";
import useWindowSize from "/hooks/useWindowSize";
import Link from "next/link";
import Image from "next/image";

const TypeShowcase = ({ data }) => {
  const size = useWindowSize();
  const getBrandsFromItem = (item) => {
    let brandString = "";
    item.brands.forEach((brand, index) => {
      if (index != item.brands.length - 1) {
        brandString = brandString + brand.name + ", ";
      } else {
        brandString = brandString + brand.name;
      }
    });
    return brandString;
  };
  return (
    <section className="mt-16 lg:mt-24">
      <div className="container">
        <div className=" flex flex-col space-y-3 pb-6">
          <h2 className="text-3xl leading-tight text-gray-700 md:text-4xl ">
            {data.title1}
            {size.width > 500 && <br />}
            <span className="text-3xl text-gray-500 md:text-4xl">
              {data.title2}
            </span>
          </h2>
        </div>
        <div className="flex flex-col space-y-6  lg:flex-row lg:space-x-6 lg:space-y-0 ">
          <Link href="/repairs/smartphones" passHref>
            <motion.div
              whileHover={{ y: -6, scale: 1.01 }}
              className="relative h-80 w-full cursor-pointer rounded-2xl shadow-lg transition-all duration-75 hover:shadow-xl lg:h-[30rem]"
            >
              <div className="relative inset-0 h-full w-full">
                <Image
                  alt="smartwatches"
                  src="/images/apple_iphone-12-promo.jpg"
                  layout="fill"
                  objectFit="cover"
                  className="rounded-2xl "
                />
              </div>

              <div className="absolute inset-0 flex h-full flex-col justify-between p-6">
                <div className="w-max rounded-full bg-purple-400  text-xs font-medium text-white">
                  
                </div>
                <div className="flex flex-row items-end justify-between space-x-6">
                  <motion.div whileHover={{ y: -8 }} whileFocus={{ y: -8 }}>
                    <span className="text-sm font-medium text-gray-100">
                      Samsung, Apple, Huawei, ...
                    </span>
                    <h4 className="text-3xl text-white">Smartphones</h4>
                  </motion.div>
                  <ArrowRightIcon className="h-6 w-6 flex-shrink-0 fill-current text-gray-100" />
                </div>
              </div>
            </motion.div>
          </Link>
          <Link href="/repairs/tablets" passHref>
            <motion.div
              whileHover={{ y: -6, scale: 1.01 }}
              className=" relative h-80 w-full cursor-pointer rounded-2xl  shadow-lg transition-all duration-75 hover:shadow-xl lg:h-[30rem]"
            >
              <div className="relative inset-0 h-full w-full">
                <Image
                  alt="smartwatches"
                  src="/images/tab.jpg"
                  priority
                  layout="fill"
                  objectFit="cover"
                  objectPosition="left"
                  className="rounded-2xl "
                />
              </div>
              <div className="absolute inset-0 flex h-full flex-col justify-between p-6">
                <div className="flex flex-row items-start justify-between space-x-6">
                  <motion.div whileHover={{ y: -8 }} whileFocus={{ y: -8 }}>
                    <span className="text-md font-medium text-gray-700">
                      Apple, Samsung, ...
                    </span>
                    <h4 className="text-gray-700">Tablets</h4>
                  </motion.div>
                  <ArrowRightIcon className="h-6 w-6 flex-shrink-0 fill-current text-gray-700" />
                </div>
              </div>
            </motion.div>
          </Link>
          <div className="flex w-full flex-col space-y-6 ">
            <Link href="/repairs/computers" passHref>
              <motion.div
                whileHover={{ y: -6, scale: 1.01 }}
                className="relative h-80 w-full cursor-pointer  rounded-2xl  shadow-lg transition-all duration-75 hover:shadow-xl lg:h-[30rem]"
              >
                <div className="relative inset-0 h-full w-full">
                  <Image
                    alt="smartwatches"
                    src="/images/laptop-promo.jpg"
                    priority
                    layout="fill"
                    objectFit="cover"
                    className="rounded-2xl "
                  />
                </div>
                <div className="absolute inset-0 z-1 flex h-full  flex-col  p-6">
                  <div className="flex flex-row items-end justify-between space-x-6">
                    <motion.div whileHover={{ y: -8 }} whileFocus={{ y: -8 }}>
                      <span className="text-md font-medium text-gray-50">
                        Acer, MSI, HP, Asus, Apple, ...
                      </span>
                      <h4 className="text-gray-50">Computers</h4>
                    </motion.div>
                    <ArrowRightIcon className="h-6 w-6 flex-shrink-0 fill-current text-gray-700" />
                  </div>
                </div>
              </motion.div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TypeShowcase;
