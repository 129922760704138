import "../styles/globals.css";
// import { AuthProvider } from "../hooks/useAuth";
import { CookiesProvider } from "react-cookie";
import { AppWrapper } from "/context/AppContext";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { pageView } from "/lib/ga/gtag";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config.js";
import { hotjar } from "react-hotjar";
import Script from "next/script";
import Layout from "@components/layout";
import { ThemeProvider } from "../context/themeContext";
import Head from "next/head.js";

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      pageView(url);
    };
    router.events.on("routerChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    hotjar.initialize(2828042, 6);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
      </Head>
      <Script
        strategy="worker"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`}
      />
      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}', {
                page_path: window.location.pathname,
            });
        `,
        }}
      />
      {/* <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}', {
                page_path: window.location.pathname,
              });
          `,
        }}
      /> */}
      <AppWrapper>
        {/* <AuthProvider> */}
        <CookiesProvider>
          <DefaultSeo {...SEO} />
          <ThemeProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ThemeProvider>
        </CookiesProvider>
        {/* </AuthProvider> */}
      </AppWrapper>
    </>
  );
};

export default MyApp;
