import { Fragment, useEffect } from "react";
import Header from "../header/header.js";
import Footer from "../footer/footer";
import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import ConsentModal from "../modals/consentModal";
import HeaderCentered from "../banners/HeaderCentered.js";
import { useRouter } from "next/router";
import headerData from "/data/header";

const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const router = useRouter();

  //SNOWFLAKE EFFECT uncomment to enable
  // useEffect(() => {
  //   if (router.pathname === "/") {
  //     // Check if the current route is the homepage
  //     const createSnowflake = () => {
  //       const snowflake = document.createElement("div");
  //       snowflake.className = "snowflake";

  //       // Randomize starting position and animation duration
  //       snowflake.style.left = `${Math.random() * 100}vw`; // Random horizontal position
  //       snowflake.style.animationDuration = `${3 + Math.random() * 5}s`; // Fall duration
  //       snowflake.style.opacity = `${0.5 + Math.random() * 0.5}`; // Random transparency
  //       snowflake.style.width = snowflake.style.height = `${
  //         5 + Math.random() * 10
  //       }px`; // Random size

  //       // Append to body and remove after animation
  //       document.body.appendChild(snowflake);
  //       setTimeout(() => snowflake.remove(), 8000); // Matches the longest animation
  //     };

  //     const interval = setInterval(createSnowflake, 200); // Regular snowflake creation

  //     return () => clearInterval(interval); // Cleanup on unmount
  //   }
  // }, [router.pathname]); // Dependency ensures effect runs on route change

  return (
    <Fragment>
      <div className="flex h-screen flex-col">
        <HeaderCentered data={headerData[router.locale].header[0]} />
        <Header />
        <motion.main
          className="relative flex-grow"
          key={router.route}
          initial="initial"
          animate="animate"
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
          }}
        >
          {children}
        </motion.main>
        <Footer />
        <ConsentModal
          state={cookies.cookieConsent === undefined ? true : false}
          setCookie={setCookie}
        />
      </div>
    </Fragment>
  );
};

export default Layout;
