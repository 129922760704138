import Image from "next/image";
import HeroHeaderBox from "./heroHeaderBox";
import SVG from "react-inlinesvg";
import { motion } from "framer-motion";
import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/outline";

const partners = [
  {
    id: 0,
    name: "Ku Leuven",
    logo_svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 133.18 47.56" style="enable-background:new 0 0 133.18 47.56;" xml:space="preserve">
<style type="text/css">
.st0{fill:#00407A;}
.st1{fill:#54BCEB;}
.st2{fill:#FFFFFF;}
.st3{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
</style>
<rect x="4.76" y="4.76" class="st0" width="128.42" height="42.81"/>
<polygon class="st1" points="0,0 0,47.56 4.76,47.56 4.76,4.76 133.17,4.76 133.17,0 "/>
<polygon class="st2" points="14.27,19.03 18.52,19.03 18.52,23.94 18.56,23.94 22.35,19.03 27.6,19.03 22.59,24.6 28.41,33.29 
23.11,33.29 19.7,27.74 18.52,29.08 18.52,33.29 14.27,33.29 "/>
<g>
<defs>
  <rect id="SVGID_1_" x="0" y="0" width="133.17" height="47.56"/>
</defs>
<clipPath id="SVGID_2_">
  <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
</clipPath>
<path class="st3" d="M41.17,27.77c0,3.95-2.07,5.85-6.29,5.85s-6.31-1.9-6.31-5.85v-8.75h4.26v7.77c0,1.46,0,3.27,2.07,3.27
  c2.01,0,2.01-1.82,2.01-3.27v-7.77h4.26V27.77z"/>
<polygon class="st3" points="47.42,19.03 51.67,19.03 51.67,29.64 57.68,29.64 57.68,33.29 47.42,33.29 	"/>
<polygon class="st3" points="58.98,19.03 70.3,19.03 70.3,22.68 63.19,22.68 63.19,24.46 69.65,24.46 69.65,27.86 63.19,27.86 
  63.19,29.64 70.51,29.64 70.51,33.29 58.98,33.29 	"/>
<path class="st3" d="M84.2,27.77c0,3.95-2.07,5.85-6.29,5.85c-4.22,0-6.3-1.9-6.3-5.85v-8.75h4.25v7.77c0,1.46,0,3.27,2.07,3.27
  c2.01,0,2.01-1.82,2.01-3.27v-7.77h4.26V27.77z"/>
<polygon class="st3" points="93.68,33.29 88.92,33.29 84.8,19.03 89.09,19.03 91.36,27.68 91.39,27.68 93.62,19.03 97.99,19.03 	
  "/>
<polygon class="st3" points="98.59,19.03 109.91,19.03 109.91,22.68 102.81,22.68 102.81,24.46 109.26,24.46 109.26,27.86 
  102.81,27.86 102.81,29.64 110.13,29.64 110.13,33.29 98.59,33.29 	"/>
<polygon class="st3" points="111.23,19.03 115.58,19.03 119.6,26.66 119.64,26.66 119.64,19.03 123.66,19.03 123.66,33.29 
  119.52,33.29 115.29,25.5 115.25,25.5 115.25,33.29 111.23,33.29 	"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
  },
  {
    id: 1,
    name: "UCLL Leuven",
    logo_svg: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.37 66.9">
<defs>
  <style>
    .cls-1 {
      fill: #e30046;
    }
  </style>
</defs>
<g>
  <g>
    <path class="cls-1" d="M90,57.61l-20.24.15a.49.49,0,0,1,0,.12c0,.14,0,.28,0,.42v.06c0,.13,0,.26,0,.38s0,0,0,.07l20.2,1.39H90a22,22,0,0,0,0-2.59Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M45.36,89.39h0a28.66,28.66,0,0,0,3.07.93h0l3.48-13.88c-.51-.42-1-.87-1.48-1.32Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M31.1,79.56l0,0c0,.05.09.1.13.16.59.66,1.18,1.3,1.8,1.92l.21.2h0l12.59-13c-.23-.45-.45-.92-.65-1.39Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M51,90.86h.05l.2,0c.86.13,1.74.24,2.61.31l.29,0h0l.7-10.1-.7,10.1.87-12.62a20.67,20.67,0,0,1-1.82-1.16Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M40.05,87h0l.22.11,0,0c.85.47,1.73.91,2.61,1.31h0L49.36,74c-.4-.44-.79-.9-1.15-1.38Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M35.25,83.64l0,0,.18.15c.68.55,1.39,1.08,2.11,1.58h0l.22.15h0l9.56-14.1c-.3-.45-.6-.92-.88-1.4Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M23.67,63.92h0c0,.07,0,.15,0,.24v0c.19,1,.41,1.92.69,2.85l19.18-5.47c-.06-.43-.11-.85-.14-1.28Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M27.74,74.81h0a2,2,0,0,1,.11.2l0,0c.46.75.93,1.48,1.44,2.2h0l.16.21,0,0L44.78,66.41c-.16-.44-.31-.9-.45-1.36Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M43.76,62.64,25.23,69.55h0c.38,1,.8,2,1.27,3h0L44,64c-.1-.43-.2-.87-.28-1.31" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M82.33,79.22a.05.05,0,0,0,0,0l.16-.19c.56-.68,1.09-1.39,1.59-2.1l.14-.21s0,0,0,0l-1.7-1.16a24.44,24.44,0,0,1-2.37,1.94Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M85.64,74.43h0c0-.08.08-.17.13-.25l0,0c.47-.84.91-1.72,1.3-2.6l-.87-.4a21.47,21.47,0,0,1-1.9,2.51Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M43.37,58.14V58h0l-20.24.12v.29c0,.87.07,1.76.14,2.62h0l0,.27v0l20.09-2.05c0-.29,0-.57,0-.86v-.26" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M78.24,83.36l0,0c0-.06.11-.1.16-.15.67-.57,1.31-1.17,1.92-1.79l.18-.18,0,0-2.49-2.42A21.87,21.87,0,0,1,75.42,80Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M66.7,81.72c-.43,0-.86,0-1.28,0l2.8,7.54h0c1-.38,2-.8,3-1.26h0l-3.06-6.28c-.47,0-.94,0-1.42,0" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M56.79,91.33h.29c.88,0,1.76,0,2.63-.14l.27,0h0l-1.1-10.76c-.74-.26-1.46-.57-2.17-.9Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M60.8,81l1.8,9.81.25-.05h0c.95-.18,1.92-.41,2.85-.67h0l-2.44-8.57A23.34,23.34,0,0,1,60.8,81" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M73.49,86.73h0l.19-.11a0,0,0,0,1,0,0c.74-.46,1.48-.93,2.19-1.44h0l.19-.15.06,0-3-4.16a22.89,22.89,0,0,1-2.78.62Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M27.49,41.37h0l-.12.21s0,0,0,0c-.48.85-.91,1.73-1.31,2.61h0l18.14,8.11c.11-.43.24-.85.38-1.28Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M79.12,38.65,82,36.17h0l0,0L81.85,36c-.57-.66-1.17-1.3-1.79-1.92l-.19-.18,0,0h0l-3.3,3.41a21.57,21.57,0,0,1,2.59,1.4" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M85.39,40.91h0l-.1-.18,0,0h0c-.45-.75-.93-1.48-1.43-2.2h0l-.17-.22,0-.05h0l-2.52,1.84a21.8,21.8,0,0,1,2.27,2Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M73.07,28.78h0l-.21-.12,0,0c-.85-.47-1.73-.92-2.62-1.32h0l-3.45,7.74a23.31,23.31,0,0,1,2.66.16Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M87.9,46.18h0c-.37-1-.8-2-1.27-3h0L85.06,44a23,23,0,0,1,1.76,2.58Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M89.47,51.8a1.89,1.89,0,0,0,0-.23v0c-.19-1-.41-1.93-.69-2.87h0l-.72.21a23.17,23.17,0,0,1,1.1,3Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M67.76,26.36h0c-1-.36-2-.67-3.08-.93h0l-2.5,10.08a24.47,24.47,0,0,1,2.47-.35Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M77.87,32.09s0,0,0,0l-.18-.14c-.67-.56-1.39-1.08-2.1-1.58h0l-.23-.17h0l-3.68,5.43a24.68,24.68,0,0,1,2.71.76Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M39.62,29.05a1,1,0,0,0-.18.1l0,0h0c-.76.46-1.49.93-2.21,1.44h0l-.2.15-.06,0L47.43,45.25c.3-.45.62-.88,1-1.31Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M30.8,36.57h0l-.16.2c-.56.68-1.09,1.39-1.58,2.11h0l-.15.22s0,0,0,0h0L44.92,50c.17-.44.35-.87.55-1.3Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M46.73,46.33,34.88,32.42l0,0-.17.15c-.67.57-1.31,1.17-1.93,1.8a1.53,1.53,0,0,1-.18.18s0,0,0,0h0L46,47.64c.24-.44.48-.88.74-1.31" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M50.51,25h0l-.26.05h0c-1,.18-1.92.42-2.86.69h0l4.25,14.87c.5-.42,1-.82,1.54-1.19Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M56.32,24.43l-3.2.17,1.43,13.88a20.89,20.89,0,0,1,1.86-1Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M44.89,26.54h0c-1,.38-2,.8-2.94,1.27h0l7.35,15.07c.39-.44.8-.87,1.22-1.28Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M62.1,24.89H62l-.15,0c-.85-.14-1.73-.25-2.6-.32l-.35,0-.83,12.2A21.5,21.5,0,0,1,60.26,36Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M67.51,65.28l15,10.22a23.55,23.55,0,0,0,1.77-1.83L68.06,64.4a10,10,0,0,1-.55.88" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M45.63,50.49l-.7-.48c-.14.35-.26.7-.38,1.05l.54.31c.17-.3.35-.6.54-.88" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M44.52,52.49l-.35-.15c-.09.34-.18.68-.25,1l.21.08c.12-.33.25-.64.39-1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M88.07,69.11h0c.36-1,.67-2,.93-3L88.77,66a20.72,20.72,0,0,1-1.25,3Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M89.48,63.46,69.59,60.1c-.06.34-.13.68-.21,1L88.77,66a15.77,15.77,0,0,0,.78-2.5Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M46.39,49.48a10.27,10.27,0,0,1,.7-.78L46,47.64c-.19.36-.35.71-.52,1.07Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M66.05,67.07l12,11.68a23,23,0,0,0,2.1-1.31L66.75,66.3a10.13,10.13,0,0,1-.7.77" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M68.62,63.28h0l17.6,7.88a23.64,23.64,0,0,0,1.29-2.24L69,62.32a8,8,0,0,1-.39,1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M48.06,47.79c.25-.21.51-.41.77-.6l-1.4-1.93c-.24.34-.48.7-.7,1.07L48,47.83a.08.08,0,0,1,0,0" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M50.81,46l-1.52-3.12c-.31.35-.61.7-.9,1.06l1.51,2.55c.29-.17.6-.34.91-.49" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M64.31,68.58l8.85,12.24c.77-.23,1.52-.48,2.26-.78L65.13,67.94c-.27.23-.54.44-.82.64" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M47.38,67.37l-1.5,1.55c.19.39.4.77.61,1.15l1.67-2c-.27-.23-.53-.46-.78-.7" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M56.57,71.09c-.32,0-.64,0-1,0l-.37,5.11-.18,2.46c.54.32,1.09.6,1.65.87l0-8.4h-.1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M45.87,65.62l-1.09.79c.14.38.3.75.45,1.12l1.28-1.09c-.22-.26-.43-.54-.64-.82" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M51.18,69.94,49.36,74c.34.39.7.75,1.07,1.12l1.7-4.8c-.32-.11-.64-.24-1-.38" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M49.18,68.83l-1.81,2.64c.27.4.55.77.84,1.15l1.84-3.25c-.29-.17-.59-.35-.87-.54" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M57.94,71l1,9.37c.63.22,1.27.42,1.91.59L59,70.87c-.34.06-.67.11-1,.15" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M43.44,59.25h0c0,.34,0,.69.06,1l.14,0a8.35,8.35,0,0,1-.15-1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M63.25,69.28c-.3.18-.61.34-.92.49l5.79,11.92c.77-.06,1.53-.13,2.26-.25Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M53.34,70.69l-1.43,5.75c.43.35.88.7,1.34,1l1.11-6.56c-.35-.06-.68-.13-1-.21" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M43.87,61.5l-.28.08c0,.36.09.71.17,1.06l.43-.16c-.12-.32-.23-.65-.32-1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M44.69,63.65,44,64c.09.36.19.73.29,1.09l.84-.5q-.26-.43-.48-.9" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M60.19,70.59l3.1,10.89q1.05.15,2.13.21L61.17,70.27c-.32.12-.65.22-1,.32" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M53,45.19l-1.33-4.6c-.37.33-.75.66-1.11,1L52,45.5c.32-.11.65-.22,1-.31" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M54.17,44.9l.51-.08.51-.07-.64-6.27c-.47.29-.94.6-1.38.92l1,5.5Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M63.05,46.38c.14.08.28.15.41.24s.3.18.44.28l.05,0,7.68-11.34q-1.09-.23-2.22-.36L63.07,46.39h0" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M64,47h0" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M65,47.69l.09.08.36.31.1.09.23.21L76.53,37.25a21,21,0,0,0-2.19-.9Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M66.69,49.4c.2.24.39.49.57.74h0L81.12,40.07a21.31,21.31,0,0,0-2-1.42L66.62,49.31l.07.09" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M68,51.19c.17.3.34.6.49.91L85.06,44a23.38,23.38,0,0,0-1.67-1.9L68,51.19h0" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M69.27,54.24,88,48.88c-.36-.79-.75-1.56-1.18-2.3L69,53.27c.12.32.22.64.32,1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M56.55,44.68h.62l.22,0h.15l.54-8c-.57.22-1.13.47-1.67.74l.05,7.22h.09" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M58.77,44.86h0c.35.06.68.13,1,.21l2.38-9.56c-.64.13-1.29.28-1.91.46Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M61,45.44c.32.11.63.24,1,.38h0l4.8-10.75h0c-.7,0-1.39,0-2.06.09L61,45.44Z" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M69.7,56.49l20-2.07a21.47,21.47,0,0,0-.59-2.55l-19.55,3.6a8.35,8.35,0,0,1,.15,1" transform="translate(-23.13 -24.43)"/>
    <path class="cls-1" d="M43.4,56.91h0c0-.43.06-.9.11-1.23h0c.06-.34.13-.68.21-1l-.09,0s.21-1.15.25-1.26h0L25.06,46.68h0c-.36,1-.67,2-.93,3.08h0l19.54,4.87c-.06.38-.11.73-.15,1L23.59,52.33a.19.19,0,0,0,0,.08l0,.17c-.14.86-.25,1.73-.31,2.6,0,.1,0,.19,0,.28v.07L43.4,56.9Z" transform="translate(-23.13 -24.43)"/>
  </g>
  <g>
    <g>
      <path class="cls-1" d="M108.13,75.94H103v4.38h-1.09V70.93H103v4h5.18v-4h1.1v9.39h-1.1Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M115.9,70.74A4.88,4.88,0,1,1,111,75.61,4.7,4.7,0,0,1,115.9,70.74Zm0,8.71a3.84,3.84,0,1,0-3.77-3.84A3.63,3.63,0,0,0,115.9,79.45Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M130.42,80.32c0-1.07,0-1.23,0-1.35h0a4.61,4.61,0,0,1-3.47,1.53,4.88,4.88,0,0,1,0-9.76,4.47,4.47,0,0,1,4.26,2.57l-1.07.29A3.47,3.47,0,0,0,127,71.78a3.57,3.57,0,0,0-3.73,3.82A3.63,3.63,0,0,0,127,79.45a3.9,3.9,0,0,0,3.38-1.68V76.43h-2.79v-1h3.81v4.91Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M133.51,70.93h6.32v1H134.6v3h4.08v1H134.6V79.3h5.26v1h-6.35Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M142.32,77.57c.1,1.34,1.22,2,2.65,2s2.2-.55,2.2-1.68c0-1.54-1.55-1.62-2.72-1.88s-2.91-.75-2.91-2.65c0-1.64,1.2-2.57,3.18-2.57,1.74,0,3,.73,3.43,2.34l-1.05.28a2.16,2.16,0,0,0-2.4-1.64c-1.37,0-2,.59-2,1.55,0,1.24,1.18,1.51,2.52,1.78s3.11.71,3.11,2.78c0,1.77-1.23,2.67-3.32,2.67-1.59,0-3.4-.53-3.69-2.65Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M158.75,77.68a4.58,4.58,0,0,1-4.39,2.82,4.88,4.88,0,0,1,0-9.76,4.46,4.46,0,0,1,4.27,2.57l-1.06.29a3.73,3.73,0,0,0-6.94,2,3.73,3.73,0,0,0,7.06,1.79Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M166.91,75.94h-5.18v4.38h-1.09V70.93h1.09v4h5.18v-4H168v9.39h-1.1Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M174.68,70.74a4.88,4.88,0,1,1-4.87,4.87A4.71,4.71,0,0,1,174.68,70.74Zm0,8.71a3.84,3.84,0,1,0-3.77-3.84A3.64,3.64,0,0,0,174.68,79.45Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M185.77,70.74a4.88,4.88,0,1,1-4.87,4.87A4.71,4.71,0,0,1,185.77,70.74Zm0,8.71A3.84,3.84,0,1,0,182,75.61,3.63,3.63,0,0,0,185.77,79.45Z" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M192.46,70.93h1.08V79.3h5v1h-6Z" transform="translate(-23.13 -24.43)"/>
    </g>
    <g>
      <path class="cls-1" d="M101.86,51.63V35.82h5.31V49.93c0,5.13,1.12,9.24,6.73,9.24s6.72-4.11,6.72-9.24V35.82h5.31V51.63c0,7.51-3.86,12.41-12,12.41s-12-4.9-12-12.41" transform="translate(-23.13 -24.43)"/>
      <path class="cls-1" d="M130.25,49.65c0-8.7,6-14.39,14.32-14.39a20.73,20.73,0,0,1,8.26,1.62l-.58,4.82a20.52,20.52,0,0,0-7.68-1.58c-5.64,0-8.51,4-8.51,9.53s2.87,9.52,8.51,9.52a20.32,20.32,0,0,0,7.68-1.58l.58,4.83A20.91,20.91,0,0,1,144.57,64c-8.3,0-14.32-5.7-14.32-14.39" transform="translate(-23.13 -24.43)"/>
      <polygon class="cls-1" points="134.02 39.72 134.02 10.95 139.71 10.95 139.71 34.62 152.53 34.62 152.53 39.72 134.02 39.72"/>
      <polygon class="cls-1" points="156.84 39.72 156.84 10.95 162.54 10.95 162.54 34.63 175.35 34.63 175.35 39.72 156.84 39.72"/>
    </g>
  </g>
</g>
</svg>
`,
  },
];

const HeroHeader = ({ data, placeholders }) => {
  return (
    <div className="">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full translate-x-1/4 translate-y-1/3 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute left-full -translate-x-1/4 -translate-y-3/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-center text-5xl font-bold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
            <span className="block text-center text-slate-700">
              {data.title1}
            </span>
            <span className="block bg-gradient-to-br from-ifixers via-ifixers to-green-200 bg-clip-text pb-3 pt-1 text-center text-transparent">
              {data.title2}
            </span>
          </h1>
        </div>
        <div className="relative mt-16 pt-8">
          <div className="container">
            <div className="flex flex-col items-center justify-center space-y-5">
              {/* New Feature Badge */}
              <div className="absolute -top-1 left-1/2 mb-3 -translate-x-1/2 transform">
                <span className=" mb-3 inline-flex items-center justify-center rounded-full bg-gradient-to-tr from-red-500 to-yellow-300 px-4 py-1 pb-1.5 pt-1  text-sm font-medium leading-5 text-white ">
                  New Feature
                </span>
              </div>

              {/* Content Section */}
              <div className="space-between relative flex w-full flex-col justify-center overflow-hidden rounded-3xl bg-gradient-to-br from-ifixers via-ifixers to-emerald-300 p-8 align-middle md:p-16 md:pt-12">
                {/* Nutcracker Decorations */}
                {/* <div
                  className="absolute bottom-[-150px] left-[-100px] h-[300px] w-[110px] overflow-hidden md:bottom-[-450px] md:left-[-100px] md:h-[480px] md:w-[200px] lg:bottom-[-410px] lg:left-[-40px]"
                  style={{
                    transform: "scale(2)", // Scale up the nutcracker
                    transformOrigin: "bottom left", // Anchor scaling at bottom-left
                  }}
                >
                  <Image
                    src="/images/nutcracker1.png" // replace with actual image path
                    alt="Left Nutcracker"
                    layout="fill"
                    objectFit="cover" // Ensure proper cropping
                  />
                </div>
                <div
                  className="absolute bottom-[-120px] right-[-80px] h-[280px] w-[110px]  overflow-hidden md:bottom-[-500px] md:right-[-100px] md:h-[500px] md:w-[200px] lg:bottom-[-470px] lg:right-[-40px]"
                  style={{
                    transform: "scale(2)", // Scale up the nutcracker
                    transformOrigin: "bottom right", // Anchor scaling at bottom-right
                  }}
                >
                  <Image
                    src="/images/nutcracker2.png" // replace with actual image path
                    alt="Right Nutcracker"
                    layout="fill"
                    objectFit="cover" // Ensure proper cropping
                  />
                </div> */}

                {/* Main Content */}
                <h1 className="text-center text-3xl font-bold tracking-tight sm:text-3xl md:text-4xl lg:text-6xl">
                  <span className="block text-center text-white">
                    {data.chatbox_title1} {data.chatbox_title2}
                  </span>
                </h1>
                <p className="mx-auto mt-6 max-w-md text-center text-base font-medium text-slate-50 sm:text-lg md:max-w-3xl md:text-xl">
                  {data.chatbox_subtitle}
                </p>
                <div className="relative flex w-full justify-center gap-8">
                  {/* Chatbox */}
                  <iframe
                    className="mx-auto mt-8 h-[400px] w-full max-w-4xl rounded-3xl lg:h-[500px]"
                    src="https://rocky.ifixers.be/chatbot-iframe/zofBwcPo-NCkllIH9Lqq-"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="hidden w-full flex-1 lg:flex " />
          </div>
          <p className="mx-auto mb-6 max-w-md text-center text-base font-medium text-gray-500 sm:text-lg md:mt-12 md:max-w-3xl md:text-xl">
            {data.subtitle}
          </p>
          <div className="container flex flex-col justify-between space-y-6 pb-5 lg:flex-row lg:space-x-12 lg:space-y-0">
            {data.box.map((box, index) => (
              <HeroHeaderBox
                key={box.id}
                applyOpenHours={index === 0}
                title={box.title}
                subText={box.sub_title}
                href={box.menu_link.href}
                image={placeholders[index]}
                incentives={box.incentives}
                className="relative w-full"
              />
            ))}
          </div>
        </div>
      </div>
      <section aria-labelledby="impact-heading" className="container mt-12">
        <div className="rounded-xl border-2 bg-white px-6 py-8 sm:px-8 sm:py-12">
          <div className="mx-auto flex max-w-7xl flex-col gap-8 lg:flex-row">
            {/* Left Image */}
            <div className="relative aspect-[4/3] w-full overflow-hidden rounded-lg lg:flex-1">
              <Image
                src="/images/students.webp"
                alt="Promotional Offer"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                
              />
            </div>

            {/* Right Content */}
            <div className="flex flex-col justify-center lg:flex-1">
              <div>
                <h2 className="text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                  {data.students.title}
                </h2>
                <p className="mt-6 text-pretty text-base text-slate-600 sm:text-lg">
                  {data.students.content}
                </p>
                <div className="mt-8">
                  <Link href="/students" passHref>
                    <div className="inline-flex items-center gap-x-2 rounded-md bg-ifixers px-4 py-3 text-base font-medium text-white transition-colors hover:bg-ifixers-700 sm:text-lg cursor-pointer">
                      <span>{data.students.button}</span>
                      <ArrowRightIcon className="h-5 w-5 flex-shrink-0" />
                    </div>
                  </Link>
                </div>
              </div>

              {/* Partner Logos */}
              <div className="mt-8 flex flex-wrap items-center gap-6">
                {partners.slice(0, 2).map((partner) => (
                  <div
                    key={partner.id}
                    title={partner.name}
                    className="flex justify-center"
                  >
                    <Affiliate
                      src={partner.logo_svg}
                      alt={`${partner.name} logo`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section aria-labelledby="impact-heading" className="container mt-12">
        <div className="relative overflow-hidden rounded-xl">
          <div className="absolute max-h-none min-h-full min-w-full object-cover ">
            <Image
              alt="People in store buying devices"
              src="/images/phone_sale.webp"
              layout="fill"
              objectFit="cover"
              priority
            />
          </div>

          <div className="relative bg-slate-900 bg-opacity-55 px-6  sm:px-12 lg:px-16">
            <div className="relative px-6 pb-36  pt-36">
              <div className="">
                <span className=" mb-3 inline-flex items-center justify-center rounded-full bg-gradient-to-tr from-red-600 to-yellow-400 px-4 py-1 pb-1.5 pt-1  text-sm font-medium leading-5 text-white ">
                  {data.sale.badge_text}
                </span>
              </div>
              <h3 className="text-5xl font-bold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
                <span className="block  text-white">{data.sale.title}</span>
              </h3>

              <p className="mt-5 text-xl font-medium text-white">
                {data.sale.subtitle}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        <div className="mx-auto mt-16 max-w-7xl px-4 sm:px-6 lg:mt-24 lg:px-8">
          <h2 className="text-center text-sm font-semibold uppercase tracking-wide text-gray-400">
            {data.affiliates.title}
          </h2>
          <div className="mt-4 grid grid-cols-2 gap-8 md:grid-cols-8">
            {data.affiliates.partners.slice(0, 8).map((partner) => (
              <div
                key={partner.id}
                className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
              >
                <Affiliate
                  src={partner.logo_svg}
                  alt={`${partner.name} logo`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;

const Affiliate = ({ src, alt, className }) => {
  return (
    <div className={`col-span-1 ${className}`}>
      <div>
        <SVG
          alt={alt}
          src={src}
          className="h-16 max-w-[12rem] xl:max-w-[16rem]"
        />
      </div>
    </div>
  );
};
